import React, { useState, useEffect } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faBriefcase,
    faCode,
    faCube,
    faFileCode,
    faGlobe,
    faLayerGroup,
} from "@fortawesome/free-solid-svg-icons";

import "tailwindcss/tailwind.css";
import "./MainFeatures.css";

const MainFeatures = () => {
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (
        <div
            id="main-features"
            className="bg-gray-100 flex items-center justify-center p-10"
        >
            <div className="container mx-auto">
                <div
                    className="text-center mb-10"
                    style={{ marginTop: "50px" }}
                >
                    <h1 className="text-5xl font-bold mb-4">Main Features</h1>
                </div>

                <div
                    className={`grid grid-cols-1 ${
                        isMobile ? "" : "md:grid-cols-3"
                    } gap-20`}
                    style={
                        isMobile
                            ? { width: "100%" }
                            : {
                                  justifyContent: "center",
                                  display: "flex",
                                  width: "100%",
                              }
                    }
                >
                    <FeatureCard
                        title="Designed for Small Businesses"
                        description="Unlock the potential of your small business with web solutions crafted with your unique needs in mind. CNG Software services are specially designed to support small enterprises looking to make a big impact online without breaking the bank."
                        iconId={faBriefcase}
                    />

                    <FeatureCard
                        title="Custom Web Design & Development"
                        description="Elevate your online presence with CNG Software custom web design and development tailored precisely to your brand’s unique essence and objectives. "
                        iconId={faCube}
                    />

                    <FeatureCard
                        title="Web Application Development"
                        description="Elevate your online presence with our CNG Software Web Application Development services. We specialize in creating dynamic, high-performance web applications designed to meet the unique challenges and objectives of your business."
                        iconId={faGlobe}
                    />
                </div>

                <div
                    className={`grid grid-cols-1 ${
                        isMobile ? "" : "md:grid-cols-3"
                    } gap-20`}
                    style={
                        isMobile
                            ? { width: "100%" }
                            : {
                                  justifyContent: "center",
                                  display: "flex",
                                  width: "100%",
                              }
                    }
                >
                    <FeatureCard
                        title="Search Engine Optimization (SE0)"
                        description="Our SEO services are designed to propel your website to the top of search engine results pages, ensuring that your content reaches its target audience effectively by leveraging the latest algorithms and best practices."
                        iconId={faCode}
                    />

                    <FeatureCard
                        title="Website Maintenance"
                        description="Our Website Maintenance services are tailor-made to provide your website with the ongoing care it deserves, ensuring it remains up-to-date, secure, and performing at its best. We take the hassle out of website management so you can focus on what you do best – growing your business."
                        iconId={faLayerGroup}
                    />

                    <FeatureCard
                        title="Analytics & Performance Optimization"
                        description="Utilizing tools like Google Analytics to track website performance and user behavior, then using this data to make informed decisions on how to improve site speed, usability, and overall performance."
                        iconId={faFileCode}
                    />
                </div>
            </div>
        </div>
    );
};

const FeatureCard = ({ title, description, iconId }) => (
    <div
        className="flex flex-col items-center text-center w-full md:w-auto"
        style={{ margin: "30px" }}
    >
        <FontAwesomeIcon
            icon={iconId}
            size="3x"
            style={{ color: "#5DAA4C", marginBottom: "20px" }}
        />
        <h5 className="text-xl font-semibold mb-2">{title}</h5>
        <p style={{ fontSize: "14px" }}>{description}</p>
    </div>
);

export default MainFeatures;
