import React from "react";

import { Link as ScrollLink } from "react-scroll";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";

import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import logo from "../../assets/images/logo.png";
import Button from "react-bootstrap/Button";
import "./MyNav.css";

const MyNav = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const isAuthenticated = localStorage.getItem("access_token");

    const handleNavigation = (to) => {
        if (location.pathname !== "/") {
            window.location.href = `/#${to}`;
        }
    };

    const handleLogout = () => {
        localStorage.removeItem("access_token");
        navigate("/blog");
    };

    return (
        <div className="nav-container">
            <Navbar
                expand="lg"
                style={{ backgroundColor: "none", padding: "0px" }}
            >
                <Container style={{ backgroundColor: "#FFF" }}>
                    <Navbar.Brand href="#home">
                        <img
                            src={logo}
                            className="cng-logo"
                            alt="CNG Software"
                        />
                    </Navbar.Brand>

                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />

                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="me-auto">
                            <Nav.Link
                                className="nav-items"
                                style={{
                                    paddingTop: "19px",
                                    paddingRight: "50px",
                                }}
                                onClick={() => handleNavigation("/")}
                            >
                                {location.pathname === "/" ? (
                                    <ScrollLink
                                        to="home"
                                        smooth={true}
                                        duration={500}
                                    >
                                        Home
                                    </ScrollLink>
                                ) : (
                                    "Home"
                                )}
                            </Nav.Link>
                            <Nav.Link
                                className="nav-items"
                                style={{
                                    paddingTop: "19px",
                                    paddingRight: "50px",
                                }}
                                onClick={() => handleNavigation("/")}
                            >
                                {location.pathname === "/" ? (
                                    <ScrollLink
                                        to="main-features"
                                        smooth={true}
                                        duration={500}
                                    >
                                        About
                                    </ScrollLink>
                                ) : (
                                    "About"
                                )}
                            </Nav.Link>
                            <Nav.Link
                                className="nav-items"
                                style={{
                                    paddingTop: "19px",
                                    paddingRight: "50px",
                                }}
                            >
                                <RouterLink
                                    to="/blog"
                                    smooth={true}
                                    duration={500}
                                >
                                    Blog
                                </RouterLink>
                            </Nav.Link>
                            <Nav.Link
                                className="nav-items"
                                href="https://us21.list-manage.com/contact-form?u=cf3c3aa381880494ee49992e3&form_id=6115ce95df81dff349796231649385ad"
                            >
                                <button className="myButton">Contact</button>
                            </Nav.Link>
                            {isAuthenticated && (
                                <Nav.Link className="nav-items">
                                    <button
                                        className="myButton"
                                        style={{
                                            backgroundColor: "green",
                                            color: "white",
                                            paddingRight: "50px",
                                            paddingLeft: "45px",
                                        }}
                                        onClick={handleLogout}
                                    >
                                        Logout
                                    </button>
                                </Nav.Link>
                            )}
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </div>
    );
};

export default MyNav;
