import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import axios from "axios";
import "tailwindcss/tailwind.css";
import * as Yup from "yup";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";

const NewPost = () => {
    const navigate = useNavigate();
    const [editorData, setEditorData] = useState("");

    // validation schema
    const PostSchema = Yup.object().shape({
        title: Yup.string().required("Title is required"),
        content: Yup.string().required("Content is required"),
    });

    const handleSubmit = async (values, { setSubmitting }) => {
        console.log("here");
        try {
            await axios.post(
                "/api/posts",
                {
                    title: values.title,
                    content: editorData,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${localStorage.getItem(
                            "access_token"
                        )}`,
                    },
                }
            );
            navigate("/blog");
        } catch (error) {
            console.error("There was an error!", error);
        }
        setSubmitting(false);
    };

    const handleCancel = () => {
        navigate("/blog");
    };

    const formStyle = {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        gap: "15px",
        margin: "30px auto",
        width: "80%",
        maxWidth: "900px",
    };

    const inputStyle = {
        width: "100%",
        padding: "10px",
        fontSize: "16px",
        borderRadius: "5px",
        border: "1px solid #ccc",
    };

    const buttonStyle = {
        padding: "10px 20px",
        fontSize: "18px",
        backgroundColor: "#5DAA4C",
        color: "white",
        border: "none",
        borderRadius: "5px",
        cursor: "pointer",
    };

    return (
        <div className="flex justify-center items-center h-screen">
            <Formik
                initialValues={{ title: "", content: "" }}
                validationSchema={PostSchema}
                onSubmit={handleSubmit}
            >
                {({ isSubmitting, setFieldValue }) => (
                    <Form className="w-full max-w-md" style={formStyle}>
                        <h2>Add Post</h2>
                        <label htmlFor="title" className="block">
                            Title:
                            <Field
                                type="text"
                                name="title"
                                style={inputStyle}
                                placeholder="Title here..."
                            />
                            <ErrorMessage
                                name="title"
                                component="div"
                                style={{ color: "red" }}
                            />
                        </label>

                        <label>
                            Content:
                            <CKEditor
                                editor={DecoupledEditor}
                                data={editorData}
                                config={{
                                    toolbar: {
                                        items: [
                                            "heading",
                                            "|",
                                            "bold",
                                            "italic",
                                            "link",
                                            "bulletedList",
                                            "numberedList",
                                            "|",
                                            "outdent",
                                            "indent",
                                            "|",
                                            "imageUpload",
                                            "blockQuote",
                                            "insertTable",
                                            "mediaEmbed",
                                            "undo",
                                            "redo",
                                        ],
                                    },
                                    language: "en",
                                    image: {
                                        toolbar: [
                                            "imageTextAlternative",
                                            "imageStyle:full",
                                            "imageStyle:side",
                                        ],
                                    },
                                    table: {
                                        contentToolbar: [
                                            "tableColumn",
                                            "tableRow",
                                            "mergeTableCells",
                                        ],
                                    },
                                }}
                                placeholder="Write something..."
                                onReady={(editor) => {
                                    if (
                                        editor.ui.view.toolbar.element &&
                                        editor.ui.getEditableElement()
                                    ) {
                                        editor.ui
                                            .getEditableElement()
                                            .parentElement.insertBefore(
                                                editor.ui.view.toolbar.element,
                                                editor.ui.getEditableElement()
                                            );
                                    }
                                }}
                                onChange={(event, editor) => {
                                    const data = editor.getData();
                                    setEditorData(data);
                                    setFieldValue("content", data);
                                }}
                                
                            />
                            <ErrorMessage
                                name="content"
                                component="div"
                                style={{ color: "red" }}
                            />
                        </label>

                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                marginTop: "10px",
                                justifyContent: "flex-start",
                                gap: "10px",
                            }}
                        >
                            <button
                                type="submit"
                                style={buttonStyle}
                                disabled={isSubmitting}
                            >
                                {isSubmitting
                                    ? "Adding New Post..."
                                    : "Add Post"}
                            </button>
                            <button
                                type="button"
                                onClick={handleCancel}
                                style={{
                                    ...buttonStyle,
                                    backgroundColor: "#6c757d",
                                }}
                            >
                                Cancel
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default NewPost;
