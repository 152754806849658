import React, { useState } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import ConfirmationDialog from "../shared/ConfirmationDialog";

import "./BlogPost.css";

const BlogPost = ({ post, author, title, createdAt, onDelete }) => {
    const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
    const navigate = useNavigate();

    

    const isAuthenticated = localStorage.getItem("access_token");

    const deleteIconClickHandler = (e) => {
        e.preventDefault();
        setShowConfirmationDialog(true);
    };

    const confirmDeleteHandler = () => {
        axios
            .delete(`/api/posts/${post._id}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem(
                        "access_token"
                    )}`,
                },
            })
            .then((response) => {
                setShowConfirmationDialog(false);

                if (typeof onDelete === "function") {
                    onDelete(post._id);
                } else {
                    navigate("/blog");
                }
            })
            .catch((error) => {
                console.error("Error deleting post:", error);
            });

     
    };

    const cancelDeleteHandler = () => {
        setShowConfirmationDialog(false);
    };

    return (
        <div style={{ marginBottom: "50px" }}>
            <hr />
            <Link to={`/posts/${post._id}`}>
                <h3>{title}</h3>
            </Link>
            <p style={{ color: "#808080" }}>
                Created on {createdAt} by {author}
            </p>

            {isAuthenticated && (
                <Link to={`/posts/edit/${post._id}`}>
                    <FontAwesomeIcon
                        className="blog-post-icon"
                        icon={faPenToSquare}
                        size="1.5x"
                    />
                </Link>
            )}
            {isAuthenticated && (
                <div
                    onClick={deleteIconClickHandler}
                    style={{ display: "inline-block", cursor: "pointer" }}
                >
                    <FontAwesomeIcon
                        className="blog-post-icon"
                        icon={faTrash}
                        size="1.5x"
                    />
                </div>
            )}
            {showConfirmationDialog && (
                <ConfirmationDialog
                    show={showConfirmationDialog}
                    message="Are you sure you want to delete?"
                    onConfirm={confirmDeleteHandler}
                    onCancel={cancelDeleteHandler}
                />
            )}
        </div>
    );
};

export default BlogPost;
