import React from "react";
import BodyContainer from "../Body/BodyContainer";

import "./MyContainer.css";

const MyContainer = () => {
    return (
        <React.Fragment>
            <div className="main-container">
                <BodyContainer />
            </div>
        </React.Fragment>
    );
};

export default MyContainer;
