import React, { useState, useEffect } from "react";
import axios from "axios";
import { Form, Container, Row, Col, Alert } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from "react-router-dom";

const Login = () => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");

    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError("");
    
        try {
            const response = await fetch("/login", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    username,
                    password,
                }),
            });
    
            if (!response.ok) {
                // Handle HTTP errors
                if (response.status === 401) {
                    throw new Error("Login failed. Please check your credentials.");
                } else {
                    throw new Error("An error occurred. Please try again later.");
                }
            }
    
            const data = await response.json();
            localStorage.setItem("access_token", data.access_token);
            console.log("Login successful", data);
            navigate("/blog");
        } catch (error) {
            setError(error.message);
        }
    };
    

    // const handleSubmit = async (e) => {
    //     e.preventDefault();
    //     setError("");

    //     try {
    //         const response = await axios.post("/login", {
    //             username,
    //             password,
    //         }, {
    //             headers: {
    //                 'Content-Type': 'application/json'
    //             }
    //         });
    //         localStorage.setItem("access_token", response.data.access_token);
    //         console.log("Login successful", response.data);
    //         navigate("/blog")
    //     } catch (error) {
    //         if (error.response && error.response.status === 401) {
    //             setError("Login failed. Please check your credentials.");
    //         } else {
    //             setError("An error occurred. Please try again later.");
    //         }
    //     }
    // };

    const buttonStyle = {
        padding: "10px 20px",
        fontSize: "18px",
        backgroundColor: "#5DAA4C",
        color: "white",
        border: "none",
        borderRadius: "5px",
        cursor: "pointer",
        marginBottom: "50px"
    };

    return (
        <div>
            <hr />
            <Container style={{ marginTop: "50px", }}>
                <Row className="justify-content-md-center">
                    <Col xs={12} md={6}>
                        
                        <h2>Login</h2>
                        {error && <Alert variant="danger">{error}</Alert>}
                        <Form onSubmit={handleSubmit}>
                            <Form.Group
                                className="mb-3"
                                controlId="formBasicEmail"
                            >
                                <Form.Label>Username</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter username"
                                    value={username}
                                    onChange={(e) =>
                                        setUsername(e.target.value)
                                    }
                                />
                            </Form.Group>

                            <Form.Group
                                className="mb-3"
                                controlId="formBasicPassword"
                            >
                                <Form.Label>Password</Form.Label>
                                <Form.Control
                                    type="password"
                                    placeholder="Password"
                                    value={password}
                                    onChange={(e) =>
                                        setPassword(e.target.value)
                                    }
                                />
                            </Form.Group>
                            <button style={buttonStyle} type="submit">
                                Login
                            </button>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default Login;
