import React from "react";

import Header from "./Header";
import MainFeatures from "./MainFeatures";



const BodyContainer = () => {

    return (
        <div className="main-body-container">
            <Header />
            <MainFeatures />
            
        </div>
            
    );
};

export default BodyContainer;
