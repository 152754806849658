import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import BlogPost from "./BlogPost";

import "tailwindcss/tailwind.css";

const Blog = () => {
    const [posts, setPosts] = useState([]);
    const navigate = useNavigate();

    const isAuthenticated = localStorage.getItem("access_token");

    useEffect(() => {
        fetchPosts();
    }, []);

    const fetchPosts = async () => {
        const response = await fetch("/api/posts");
        const data = await response.json();
        setPosts(data);
    };

    const deletePost = (postId) => {
        // Your existing delete logic
        fetch(`/api/posts/${postId}`, {
            method: "DELETE",
        })
            .then((response) => {
                if (response.ok) {
                    // Filter out the deleted post and update state
                    const updatedPosts = posts.filter(
                        (post) => post._id !== postId
                    );
                    setPosts(updatedPosts);

                    // Optionally navigate to /blog to refresh the list
                    navigate("/blog");
                } else {
                    console.error("Failed to delete the post.");
                }
            })
            .catch((error) => console.error("Error deleting post:", error));
    };

    return (
        <div className="container mx-auto" style={{ paddingTop: "50px" }}>
            {isAuthenticated && (
                <Link to="/new-post">
                    <button
                        className="myButtonHeader"
                        style={{ marginLeft: "30px" }}
                    >
                        Add New Post
                    </button>
                </Link>
            )}
            <ul>
                {posts
                    .sort(
                        (a, b) =>
                            new Date(b.created_at) - new Date(a.created_at)
                    )
                    .map((post) => (
                        <li key={post._id}>
                            <BlogPost
                                post={post}
                                author={post.author}
                                title={post.title}
                                createdAt={new Date(
                                    post.created_at
                                ).toLocaleDateString()}
                                onDelete={() => deletePost(post._id)}
                            />
                        </li>
                    ))}
            </ul>
        </div>
    );
};

export default Blog;
