import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import axios from "axios";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";

const EditPost = () => {
    const { postId } = useParams();
    const navigate = useNavigate();
    const [post, setPost] = useState({ title: "", content: "" });

    useEffect(() => {
        const fetchPost = async () => {
            try {
                const response = await axios.get(
                    `/api/posts/${postId}`
                );
                const { title, content } = response.data;
                setPost({ title, content });
            } catch (error) {
                console.error("Error fetching post:", error);
            }
        };

        fetchPost();
    }, [postId]);

    const formStyle = {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        gap: "15px",
        margin: "30px auto",
        width: "80%",
        maxWidth: "900px",
    };

    const inputStyle = {
        width: "100%",
        padding: "10px",
        fontSize: "16px",
        borderRadius: "5px", // Rounded corners
        border: "1px solid #ccc", // A subtle border
    };

    const buttonStyle = {
        padding: "10px 20px",
        fontSize: "18px",
        backgroundColor: "#5DAA4C",
        color: "white",
        border: "none",
        borderRadius: "5px",
        cursor: "pointer",
    };

    return (
        <Formik
            initialValues={post}
            enableReinitialize={true}
            onSubmit={async (values, { setSubmitting }) => {
                try {
                    await axios.put(
                        `/api/posts/${postId}`,
                        {
                            title: values.title,
                            content: values.content,
                        },
                        {
                            headers: {
                                "Content-Type": "application/json",
                                Authorization: `Bearer ${localStorage.getItem(
                                    "access_token"
                                )}`,
                            },
                        }
                    );

                    navigate("/blog");
                } catch (error) {
                    console.error("There was an error!", error);
                } finally {
                    setSubmitting(false);
                }
            }}
        >
            {({ handleSubmit, isSubmitting, setFieldValue }) => (
                <Form onSubmit={handleSubmit} style={formStyle}>
                    <h2>Update Post</h2>
                    <div>
                        <label htmlFor="title">Title:</label>
                        <Field
                            id="title"
                            name="title"
                            placeholder="Title Here"
                            style={inputStyle}
                        />
                    </div>
                    <div>
                        <label>Content:</label>
                        <CKEditor
                            editor={DecoupledEditor}
                            data={post.content}
                            onReady={(editor) => {
                                if (
                                    editor.ui.view.toolbar.element &&
                                    editor.ui.getEditableElement()
                                ) {
                                    editor.ui
                                        .getEditableElement()
                                        .parentElement.insertBefore(
                                            editor.ui.view.toolbar.element,
                                            editor.ui.getEditableElement()
                                        );
                                }
                            }}
                            onChange={(event, editor) => {
                                const data = editor.getData();
                                setFieldValue("content", data);
                            }}
                        />
                    </div>
                    <button
                        type="submit"
                        disabled={isSubmitting}
                        style={buttonStyle}
                    >
                        Update Post
                    </button>
                </Form>
            )}
        </Formik>
    );
};

export default EditPost;
