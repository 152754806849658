import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';

import "tailwindcss/tailwind.css";

const BlogPostDetail = () => {
    const { postId } = useParams();
    const [post, setPost] = useState(null);

    useEffect(() => {
        const fetchPost = async () => {
            const response = await fetch(`/api/posts/${postId}`);
            const data = await response.json();
            setPost(data);
        }

        fetchPost();
    }, [postId]);

    if (!post) return <div>Loading....</div>

    return (
        <div className="container mx-auto" style={{ paddingTop: '50px'}}>
            <hr />
            <h2>{post.title}</h2>
            {/* <p>{post.content}</p> */}
            <div dangerouslySetInnerHTML={{ __html: post.content }} />
        </div>
    );  
};

export default BlogPostDetail;
