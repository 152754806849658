import React from "react";

import backgroundImage from "../../assets/images/header.png";
import "./Header.css";

const Header = () => {
    // const backgroundStyle = {
    //     backgroundImage: `url(${backgroundImage})`,
    //     backgroundSize: "cover",
    //     backgroundRepeat: "no-repeat"
    // }

    const backgroundStyle = {
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "150% 150%", // Increase the size for the zoom effect
        backgroundRepeat: "no-repeat",
        animation: "zoom 10s infinite", // Add animation
    };

    return (
        <React.Fragment>
            <div id="home" className="header-container" style={backgroundStyle}>
                <div className="header-content">
                    <h1>Let's Grow Together</h1>
                    <p>
                        Embark on a journey of growth and innovation with a
                        partner who genuinely believes in your success.
                        <br /> "Let's Grow Together" is more than a slogan; it's
                        our commitment to being your trusted ally in navigating
                        the digital landscape.
                    </p>
                    <a href="https://us21.list-manage.com/contact-form?u=cf3c3aa381880494ee49992e3&form_id=6115ce95df81dff349796231649385ad">
                        <button className="myButtonHeader">Contact Us</button>
                    </a>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Header;
