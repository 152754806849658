import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import MyContainer from "./components/shared/MyContainer";
import EditPost from "./components/Blog/EditPost";
import MyNav from "./components/Nav/MyNav";
import Footer from "./components/Footer/Footer";
import Blog from "./components/Blog/Blog";
import NewPost from "./components/Blog/NewPost";
import BlogPostDetail from "./components/Blog/BlogPostDetail";
import Login from "./components/Authentication/Login";

function App() {
    return (
        <Router basename="/">
            <div>
                <MyNav />
                <Routes>
                    <Route path="/" element={<MyContainer />} />
                    <Route path="/blog" element={<Blog />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/posts/:postId" element={<BlogPostDetail />} />
                    <Route path="/new-post" element={<NewPost />} />
                    <Route path="/posts/edit/:postId" element={<EditPost />} />
                </Routes>
                <Footer />
            </div>
        </Router>
    );
}

export default App;
