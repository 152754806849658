import React from "react";

import backgroundImage from '../../assets/images/background-end.png';
import "./Footer.css";

const Footer = () => {

    const currentYear = new Date().getFullYear();

    const backgroundStyle = {
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat"
    }

    return (
        <React.Fragment>
            <div className="footer-container" style={backgroundStyle}>
                <div className="footer-content">
                   <footer>&copy; 2023 - {currentYear} CNG Software. All rights reserved.</footer>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Footer;
